<template>
  <v-container class='pt-7 pb-11'>
    <h1 class='text-h4 mb-5'>{{ 'controls'|localize }}</h1>
    <div class='white elevation-2 rounded'>
      <v-row class='ma-0' align='center'>
        <v-col cols='auto'>
          <div class='subtitle-2'>{{ 'notificationOnClientUI'|localize }}</div>
          <i class='text-caption'>{{ 'activatesNotificationMessage'|localize }}</i>
        </v-col>
        <v-col cols='auto'>
          <v-switch
            v-model='client_notification'
            hide-details
            class='ma-0 ml-5'
          />
        </v-col>
      </v-row>
      <v-row class='ma-0'>
        <v-col cols='auto' class='ml-auto'>
          <v-btn
            depressed
            rounded
            color='primary'
            :loading='saveLoading'
            :disabled='saveLoading'
            @click='saveSettings'>
            {{ 'save'|localize }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';

  export default {
    name: 'AdminGlobalSettings',

    data() {
      return {
        saveLoading: false,
        client_notification: false,
      };
    },

    mounted() {
      this.setSettings();
    },

    computed: {
      apiSettings() {
        return this.$store.getters.apiSettings;
      }
    },

    methods: {
      setSettings() {
        this.apiSettings.client_notification ? this.client_notification = true : this.client_notification = false;
      },
      queryData() {
        let queryData = {};
        queryData.client_notification = this.client_notification;
        return queryData;
      },
      async saveSettings() {
        try {
          this.saveLoading = true;
          await api.setGlobalSettings(this.queryData());
          let settingsData = await api.getGlobalSettings();
          this.$store.commit('changeApiSettings', settingsData.data);
          this.setSettings();
          methods.showGlobalSnackbar('success', localize('done'));
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        } finally {
          this.saveLoading = false;
        }
      }
    }
  };
</script>
